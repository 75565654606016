/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

@import 'swiper/scss';
@import '@ionic/angular/css/ionic-swiper';
@import "swiper/scss/autoplay";

/* For Desktop View */
@media screen and (min-width: 1024px) {
    .dish_image{
      width: 40%;
      margin-left: 60%;
    }
    .veg_item_card_content {
        border-style: solid;
        border-right: none;
        border-left: none;
        border-width: 0.5px;
        border-color: #d4cfcf;
        border-top: none;
        padding-inline-start: 10px;
        padding-bottom: 1% !important;
        padding-top: 1% !important;
    }
    .vn_icon{
      float: left;
      font-size: 32px;
    }
    .best_seller_div h2 {
      margin-left: 0;
      margin-right: 0;
      margin-top: 2px;
      margin-bottom: 2px;
      font-weight: normal;
      color: white;
      font-size: 27px !important;
  }
  .item_title {
    margin-top: 2%;
    font-size: 24px;
    /* font-family: inherit; */
    font-weight: 300;
    color: #000000;
    float: left;
    width: 100%;
    -webkit-font-smoothing: subpixel-antialiased;
} 
  .in_menu {
    font-size: 12px;
    color: #000000;
    font-weight: 400;
    width: 100%;
    float: left;
  }
  .item_price {
    margin-top: 2%;
    font-size: 21px;
    font-family: sans-serif;
    font-weight: 500;
    color: rgb(0, 0, 0);
    float: left;
    margin-right: 3%;
    /* font-weight: 500; */
    -webkit-text-security: none;
  }

    .item_description {
      margin-top: 2% !important;
      font-family: sans-serif;
      color: #5b5858;
      font-weight: 300;
      font-size: 24px !important;
      min-height: 33px;
      float: left;
      width: inherit;
    }
    .chip_share_fav {
      width: 24px;
      text-align: center;
      height: 24px;
      border-radius: 25px;
      margin-top: 5% !important;
      position: relative;
      float: left;
      margin-left: 12px !important;
      margin-bottom: 0px;
    }

    .headers {
      font-weight: 600;
      font-family: sans-serif;
      font-size: 31px !important;
      --ion-color-base: black !important;
    }

    .dish_content{
      padding-left: 3%;
    }

    .button_card_add {
     // display: none;
    }

    ion-tab-bar{
     // display: none;
    }

    .offerImage {
      border-radius: 8px;
      height: 100% !important;
      margin-bottom: 3%;
  }
  
  .swipSlider{
    width: 45% !important; 
  }

  }
  
  /* For Tablet View */
  @media screen and (min-device-width: 768px)
  and (max-device-width: 1024px) {
  .gfg-div {
    width: 400px;
    height: 400px;
    background-color: orange;
    color: black;
  }
  }
  
  /* For Mobile Portrait View */
  @media screen and (max-device-width: 480px)
  and (orientation: portrait) {
  .gfg-div {
    width: 200px;
    height: 200px;
    background-color: red;
    color: #fff;
  }
  }
  
  /* For Mobile Landscape View */
  @media screen and (max-device-width: 640px)
  and (orientation: landscape) {
  .gfg-div {
    width: 400px;
    height: 200px;
    background-color: cyan;
    color: black;
  }
  }
  
  /* For Mobile Phones Portrait or Landscape View */
  @media screen and (max-device-width: 640px) {
  .gfg-div {
    width: 400px;
    height: 200px;
    background-color: chartreuse;
    color: black;
  }
  }
  
  /* For iPhone 4 Portrait or Landscape View */
  @media screen and (min-device-width: 320px)
  and (-webkit-min-device-pixel-ratio: 2) {
  .gfg-div {
    width: 400px;
    height: 400px;
    background-color: brown;
    color: black;
  }
  }
  
  /* For iPhone 5 Portrait or Landscape View */
  @media (device-height: 568px) and (device-width: 320px)
  and (-webkit-min-device-pixel-ratio: 2) {
  .gfg-div {
    width: 400px;
    height: 400px;
    background-color: cornflowerblue;
    color: black;
  }
  }
  
  /* For iPhone 6 and 6 plus Portrait or Landscape View */
  @media (min-device-height: 667px) and (min-device-width: 375px)
  and (-webkit-min-device-pixel-ratio: 3) {
  .gfg-div {
    width: 400px;
    height: 400px;
    background-color: darkgoldenrod;
    color: black;
  }
  }
  

.item_name_holder input{
  padding: 0px !important;
}

.padding_bottom{
    padding-bottom: 21px i !important;
  }

  #offerGrid .swiper-container {
    position: relative;
    overflow: hidden;
    list-style: none;
    padding-bottom: 15px;
    z-index: 1;
    height: 226px;
    padding: 0%;
    padding-top: 4%;
}

.offerImage{
  border-radius: 8px;
  height: 196px;
  margin-bottom: 3%;
}

.appLocationIcon{
  margin-left: 12px;
  margin-top: 5px;
  font-size: 20px;
  color: #f03838;
  float: left;
}

.downIcon{
  float: left;
}

.appLocation{
  color: black;
    margin: 0px;
    padding: 0px;
    text-align: left;
    margin-left: 40px;
    font-weight: 800;
    float: left;
    margin-top: -9px;
}

.appLocationStreet{
  margin-top: 17px;
    position: absolute;
    font-size: 13px;
    margin-left: 5px;
    color: #454343;
    font-weight: 400;
}

.toggle_label{
  font-size: 15px;
  margin-left: 7px;
  font-weight: 400;
  line-height: 2;
}

.img{
  max-width: 108%;
  border: 0;
  border-radius: 6px;
}

#search_bar .searchbar-input-container{
  border-style: solid;
  border-width: 1px;
  border-radius: 6px;
  border-color: #e4e3e3;
  padding: 1px;
  --box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0), 0 3px 1px -2px rgba(0, 0, 0, 0), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
}


// :root {
//   --ion-color-secondary: #f27329 !important;
//   --ion-color-secondary-rgb: 0,102,0;
//   --ion-color-secondary-contrast: white;
//   --ion-color-secondary-contrast-rgb: 255,255,255;
//   --ion-color-secondary-shade: white;
//   --ion-color-secondary-tint: white;

//   --ion-color-rose: #831843;
//   --ion-color-rose-rgb: 131, 24, 67;
//   --ion-color-rose-contrast: #ffffff;
//   --ion-color-rose-contrast-rgb: 255, 255, 255;
//   --ion-color-rose-shade: #73153b;
//   --ion-color-rose-tint: #8f2f56;

//   --ion-color-step-50: #f9e6e9;
//   --ion-color-step-100: #f3dbdf;
//   --ion-color-step-150: #edd0d6;
//   --ion-color-step-200: #e7c5cd;
//   --ion-color-step-250: #e1bac3;
//   --ion-color-step-300: #dbaeba;
//   --ion-color-step-350: #d5a3b1;
//   --ion-color-step-400: #cf98a7;
//   --ion-color-step-450: #c98d9e;
//   --ion-color-step-500: #c48295;
//   --ion-color-step-550: #be778b;
//   --ion-color-step-600: #b86c82;
//   --ion-color-step-650: #b26178;
//   --ion-color-step-700: #ac566f;
//   --ion-color-step-750: #a64b66;
//   --ion-color-step-800: #a03f5c;
//   --ion-color-step-850: #9a3453;
//   --ion-color-step-900: #94294a;
//   --ion-color-step-950: #8e1e40;
// }

.ion-color-rose {
  --ion-color-base: var(--ion-color-rose);
  --ion-color-base-rgb: var(--ion-color-rose-rgb);
  --ion-color-contrast: var(--ion-color-rose-contrast);
  --ion-color-contrast-rgb: var(--ion-color-rose-contrast-rgb);
  --ion-color-shade: var(--ion-color-rose-shade);
  --ion-color-tint: var(--ion-color-rose-tint);
  margin-bottom: 9%;
}

ion-datetime {
  --background: #fff1f2;
  --background-rgb: 255, 241, 242;

  border-radius: 16px;
  box-shadow: rgba(var(--ion-color-rose-rgb), 0.3) 0px 10px 15px -3px;
}

.md ion-datetime {
  --background: #fff1f2;
  --background-rgb: 255, 241, 242;

  border-radius: 16px;
  box-shadow: rgba(var(--ion-color-rose-rgb), 0.3) 0px 10px 15px -3px;
    width: 81%;
}

.button-solid{
  // --box-shadow: 0 0px 0px 0px rgba(0, 0, 0, 0.2), 0 0px 0px 0 rgba(0, 0, 0, 0), 0 0px 0px 0 rgba(0, 0, 0, 0.12)!important;
  width: -webkit-fill-available;
  
}

.login_registerButt{
  background-color: black;
  height: 57px;
  border-radius: 5px;
  font-size: xx-large;
  font-family: revert;
  font-weight: 900;
}

#map {
  margin: 2em 1em;
  height: 250px;
  border: 1px solid black;
  background-color: transparent;
}

.button-native{
  border-radius: 0px !important;
}

capacitor-google-map {
  display: inline-block;
  width: 100%;
  height: 400px;
}

.mpamap{
  display: inline-block;
  width: 100%;
  height: 90%;
  background-color: transparent;
}

.headers{
  font-size: larger;
  font-weight: 900;
  font-family: system-ui;
  color:black !important;
  --ion-color-base: black !important;
}

ion-toast {
  color: black;
  font-size: 17px;
  font-weight: 500;
  border-style: solid;
  border-width: 1px;
}

.cuisine_slides{
  margin-top: 2%;
}

#top_cousines .cousine_slide{
  // width: 25% !important;
  margin: 1px;
}

.toolbar-background{
  background-color: transparent !important;
}

.ion_bk_button{
  margin-left: 25%;
  font-size: 14px;
}

.ion_bk_button button{
  color: #02b311;
}

.sc-ion-action-sheet-ios{
    color: #02b311;
}

.cusins_page_header{
  position: absolute;
  width: 12%;
  position: absolute;
}

.card-content-md p{
  margin-left: 0;
  margin-right: 0;
  margin-bottom: 2px;
  font-size: 14px;
  font-weight: normal;
  line-height: 3.3;
  font-weight: bold;
}

ion-toggle {
  --background: #e6e6e6;
  --background-checked: #7a49a5;

  --handle-background: #ffffff;
  --handle-background-checked: #000;

    height: 23px;
    width: 32px;
}

.md .appLocation {
  color: black;
  margin: 0px;
  padding: 0px;
  text-align: left;
  margin-left: 2%;
  font-weight: 800;
  float: left;
  margin-top: -9px;
}

.md .appLocationStreet {
  margin-top: 0px;
  position: absolute;
  font-size: 13px;
  margin-left: 5px;
  color: #454343;
  font-weight: 400;
}

.ios .location_div{
  float: left;
  margin-left: 0%;
}

.md .ion_bk_button {
  margin-left: 0px;
  font-size: 3px;
}

.md .back_button{
  width: 39px;
  background-color: rgb(207, 207, 207);
  border-radius: 53%;
  margin-left: 14%;
  padding-left: 3%;
  margin-top: 0%;
  height: 37px;
}

.md .ionbkbutton{
  --color: black;
    font-size: 14px;
    margin-left: -14% !important;
}

.ios ion-toggle {
  --background: #e6e6e6;
  --background-checked: #7a49a5;

  --handle-background: #ffffff;
  --handle-background-checked: white;

    height: 20px;
    --handle-spacing: 3px;
    width: 32px;
    --handle-width: 17px;
}

.md ion-toggle {
  --background: #2d2727;
    --background-checked: #7a49a5;
    --handle-background: #ffffff;
    --handle-background-checked: white;
    height: 18px;
    --handle-spacing: 1px;
    width: 27px;
    --handle-width: 16px;
    margin-top: -3%;
    --handle-height: calc(32px - (9px * 2));
    padding-right: 0px;
    padding-left: 0px;
}

ion-toggle::before {
  position: absolute;
  z-index: 1;
}

.sp_address{
  font-size: 11px;
  font-weight: 400;
  margin-top: 2%;
  color: #2d2d2d;
  width: fit-content;
  float: left;
}

.add_buttons{
  width: 20%;
  margin-top: -3%;
}

.add_edit{
  width: 50%;
  float: left;
}

.share_icon{
  padding: 1px;
  margin: 0px;
  width: 19px;
  margin-left: 15%;
  margin-top: 33%;
  font-size: 14px;
  border-radius: 25px;
  text-align: center;
}


.share_edit{
  padding: 1px;
  margin: 0px;
  width: 19px;
  margin-left: 15%;
  margin-top: 33%;
  font-size: 14px;
  border-radius: 25px;
  text-align: center;
}


.home_div{
  width: 10%;
  float: left;
  margin-left: 3%;
}

.home{
  font-size: 15px;
  font-weight: 700;
  margin-top: 2%;
}

.address{
  width: 60%;
  float: left;
  margin-right: 5%;
}

.ios .back_button{
  width: 39px;
  background-color: rgb(207 207 207);
  border-radius: 53%;
  margin-left: 2%;
  padding-left: 3%;
  margin-top: 2%;
}

.md .back_button_toolbar{
  width: 35px;
}

.md .header-md::after{
  height: 0px;
}

.ios .back_button_toolbar{
  width: 35px;
  background-color: #cfcfcf7a;
  border-radius: 53%;
  margin-left: 2%;
  margin-top: 2%;
}

#top_cousines .item-inner{
  border: none !important;
}

.cousin_title{
    margin: 1px;
    padding: 1px;
}

.hotels_title{
  margin: 1px;
    padding: 1px;
    float: left;
    width: 100%;
}

#top_cousines h3{
  font-family: sans-serif;
    color: #777474;
    font-weight: 600;
    padding: 1px;
    margin: 1px;
    font-size: small;
}

#recent_searches .item-native{
  background-color: gray !important;
}

.ios .toll_header{
  float: left;
  // overflow: hidden;
  // height: 32px;
  line-break: anywhere;
  font-size: 20px;
  font-weight: 500;
  color: #403f3f;
  margin: 0px;
  margin-left: 3%;
  margin-top: 1%;
  font-family: fantasy;
}

.md .toll_header{
  float: left;
  line-break: anywhere;
  font-size: 31px;
  font-weight: 500;
  color: #403f3f;
  margin: 0px;
  margin-left: 0%;
  margin-top: 3%;
  font-family: fantasy;
}

.ios .toll_header h3{
  margin: 0px;
  margin-top: 2%;
  font-size: 19px;
}

.md .toll_header h3{
  margin: 0px;
  margin-top: 2%;
  font-size: 22px;
}

#toggle_item .item-native{
  background-color: transparent;
}

#offerGrid .overlay{
  width: 100%;
}

#offerGrid img{
  width: 100%;
}

#offerGrid ion-slide{
  padding: 10px;
  // width: 100%;
}

.empty_img{
  float: left;
  min-height: 37px;
  width: 100%;
}

.collapsible{
  float: right;
  font-size: 15px !important;
  color: #a19d9d;
}

.veg_icon{
  width: 100%;
  margin-right: 2%;
}

.best_seller_icon{
  width: 40%;
  margin-left: 5%;
}

.veg_item_card_content{
  border-style: solid;
  border-right: none;
  border-left: none;
  border-width: 0.5px;
  border-color: #d4cfcf;
  border-top: none;
  padding-inline-start: 10px;
  padding-bottom: 7%;
  padding-top: 6%;
}

.desert_item_card_content{
  border-style: solid;
  border-right: none;
  border-left: none;
  border-width: 1px;
  border-color: #bbbbbb;
  border-top: none;
}

.non_veg_item_card_content{
  border-style: solid;
  border-right: none;
  border-left: none;
  border-width: 1px;
  border-color: #bbbbbb;
  border-top: none;
}

.offer_icon{
  width: 7%;
  margin-right: 2%;
  margin-top: 2%;
  float: left;
}

ion-loading.custom-loading {
  --background: #ffffff;
  --spinner-color: #1c6dff;
  background-color: #ffffff;
  color: #1c6dff;
}

.list_label{
  border-style: none;
  --border-color: transparent;
  --border-style: none !important;
}

.item_title {
  margin-top: 2%;
  font-size: 17px;
  font-family: sans-serif;
  font-weight: 600;
  color: hsl(15.92deg 1.66% 18.17%);
  float: left;
  width: 100%;
  -webkit-font-smoothing: subpixel-antialiased;
} 

.item_price{
  margin-top: 2%;
  font-size: 21px;
  font-family: sans-serif;
  font-weight: 500;
  color: rgb(0, 0, 0);
  float: left;
  margin-right: 3%;
  -webkit-text-security: none;
}

.item_description{
  margin-top: 6%;
  color: #5c5a5a;
  min-height: 33px;
  float: left;
  width: inherit;
  font: -webkit-small-control;
}

.lineThrough{
  text-decoration: line-through;
}

.in_menu{
  font-size: 12px;
  color: #000000;
  font-weight: 400;
  width: 100%;
  float: left;
}

.chip_share_fav{
  width: 24px;
  text-align: center;
  height: 24px;
  border-radius: 25px;
  margin-top: 0%;
  position: relative;
  float: left;
  margin-left: 0px;
  margin-bottom: 0px;
}

.fav_ticked_hotel{
  background-color: transparent;
}

.headers{
  font-family: system-ui;
  font-size: 22px;
  --ion-color-base: black !important;
  font-variant-caps: all-small-caps;
  font-weight: 500;
}

.fav_ticked{
  position: absolute;
  font-size: 19px;
  background-color: transparent;
  border-radius: 25px;
  border-width: 1px;
  border-color: #02ca53;
  padding: 2px;
  padding-bottom: 0px;
  color: #e64040;
  margin-left: -46%;
  margin-top: 10%;
}

.fav_unticked{
  position: absolute;
  font-size: 19px;
  background-color: transparent;
  border-radius: 25px;
  border-width: 1px;
  border-color: #02ca53;
  padding: 2px;
  padding-bottom: 0px;
  color: #e64040;
  margin-left: -46%;
  margin-top: 10%;
}

.share{
  position: absolute;
  font-size: 23px;
  background-color: transparent;
  border-radius: 25px;
  border-width: 1px;
  border-color: #02ca53;
  padding: 2px;
  padding-bottom: 0px;
  color: #e64040;
  margin-left: -46%;
  margin-top: 0%;
}

.share_hotel{
  background-color: transparent;
}

.button_card{
  margin: 0px;
  width: 86%;
  text-align: center;
  margin-top: -29px;
  margin-left: 10%;
  border: solid;
  border-width: 1px;
  border-color: #02ca53;
  z-index: 2;
}

.button_grid{
  padding: 0px;
}

.ion_button{
  margin: 0px;
}

.ion_button_pm{
  width: 100%;
  height: 46px;
  color: #03c203;
  background-color: white;
  font-size: 22px;
  font-weight: 800;
  text-align: center;
}

.button_col{
  padding: 0px;
  text-align: center;
}

.number_of_items{
  line-height: 3;
  font-weight: 900;
  font-size: 14px;
  margin-bottom: 0px;
}

.button_card_add{
  margin: 0px;
  width: 86%;
  text-align: center;
  margin-top: -29px;
  margin-left: 10%;
  border: solid;
  border-width: 1px;
  border-color: #02ca53;
  z-index: 2;
}

.category_cards{
  width: 100%;
  margin-left: 0px;
  margin-bottom: 0px;
  // margin-top: 40px;
}

#coupouns  .swiper-wrapper{
  height: auto;
  margin: 0 0;
}

#coupouns .coupoun_slide{
  width: 40% !important;
  margin-left: 4%;
  margin-right: 2%;
  color: black;
  font-weight: bold;

}

.rating_text{
  color: black;
  font-size:12px;
  text-align: left;
}

#coupouns h3{
  font-size: 20px;
    text-align: left;
    margin-top: 5px;
    font-weight: 800;
}
// .swiper-wrapper{
//   background-color: green;
//   width: 34%;
// }

.lcoation_inkm{
  float: left;
  width: 100%;
}

.in_km h6{
  margin: 0px;
  font-size: 14px;
  font-weight: 800;
}

.hotel_in_km h6{
  margin: 0px;
  font-size: 10px;
}

.best_seller_div{
  float: left;
  margin-left: 5%;
  padding: 1px;
  background-color: green;
  border-radius: 4px;
  padding-left: 8px;
  padding-right: 8px;
}

.best_seller_div h2{
  margin-left: 0;
  margin-right: 0;
  margin-top: 2px;
  margin-bottom: 2px;
  font-size: 11px;
  font-weight: normal;
  color: white;
}

.greyed {
  filter: grayscale(100%);
}

.swiper-pagination-bullets{
  bottom: 0px !important;
}

.swiper-pagination {
  display: flex;
  justify-content: center;
  position: inherit !important;
  width: 100% !important;

  .swiper-pagination-bullet {
    width: 20px !important;
    height: 20px !important;
    text-align: center;
    line-height: 20px !important;
    font-size: 12px;
    opacity: 1;
    border-radius: 100%;
    background: rgba(0, 0, 0, 0.2);
    margin: 3px;
    cursor: pointer;
  }
  .swiper-pagination-bullet-active {
    transform: scale(1);
  }
}

.off_image{
  max-width: 100%;
  border: 0;
  height: 224px;
  width: 100%;
}

.offer_header{
  margin-top: 3%;
  width: 100%;
  float: left;
}

.off_icon{
  float: left;
  width: 8%;
  margin-left: 2%;
  margin-right: 3%;
  margin-top: 2%;
}

.off_image_icon{
  max-width: 90%;
  border: 0;
  margin-left: -3%;
}

.off_head{
  float: left;
  width: 85%;
}

.offer_header_label{
  font-size: 23px;
  margin-left: 1px;
  font-weight: 700;
  line-height: 1.7;
}

.offer_content{
  float: left;
  width: 100%;
}

.des_point{
  float: left;
  margin-left: 13%;
  width: 80%;
}

.off_points{
  font-size: 12px;
  margin-left: 7px;
  font-weight: 500;
  line-height: 2;
  color: black;
}

.offer_description{
  font-size: 15px;
  margin-left: 7px;
  font-weight: 400;
  line-height: 2;
  color: black;
  font-family: sans-serif;
  font-weight: 500;
}

.beer{
  height: 10px;
  color: #016164;
}

.offer_des{
  font-size: 15px;
  font-weight: 400;
}

.offer_des_div{
  float: left;
  width: 98%;
  margin-bottom: 3%;
}

.item_ion_card_content{
  padding: 0px;
  width: 98%;
  display: block;
  border-radius: 7px;
  margin-top: 3%;
  border-style: solid;
  border-color: #f0eaea;
  border-width: 1px;
  margin-left: 1%;
}

.item_img{
  width: 94% !important;
  border: 0px;
  border-style: none;
  border-radius: 6px;
  min-height: 63px;
  height: 167px;
}

.item_slides{
  margin-top: 2%;
  width: 105%;
}

.item_image_slides{
  border-radius: 15px;
}

.cart_item_price{
  width: 40%;
  text-align: left;
  font-size: 16px;
  font-weight: 400;
  float: left;
  margin-top: 1%;
  margin-left: 2%;
}

.item_grid{
  //padding-bottom: 7%;
}

.map_items{
  --border-color: #e1dcdc;
  --padding-start: 0%;
}

.add_address{
  float: left;
  width: 10%;
  margin-left: 4%;
  color: #02b311;
  font-weight: 600;
  font-size: 19px;
}

.add_address_text{
  float: left;
  width: 50%;
  margin-left: 2%;
  color: #02b311 !important;
  font-weight: 600;
  font-size: 16px;
}

.swiper-pagination {
  position: absolute;
  text-align: center;
  transition: opacity 0.3s ease 0s;
  transform: translate3d(0px, 0px, 0px);
  z-index: 10;
  margin-top: 3%;
}

.swiper {
  width: 100%;
  height: 100%;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  overflow: clip;
  list-style: none;
  padding: 0px;
  z-index: 1;
  display: block;
  margin-bottom: 3%;
}

.OfferSwipper{
  --swiper-pagination-bullet-inactive-color: blue !important;
  --swiper-pagination-color: black;
  --swiper-pagination-progressbar-bg-color: black;
  --swiper-pagination-color: black;
  --swiper-scrollbar-bg-color: black;
  --swiper-scrollbar-drag-bg-color: black;
   //width: 95%;

  .swiper{
    margin-bottom: 4%;
  }
  
}

.OfferSwipper .swiper-slide {
  display: flex;
  position: relative;
  flex-direction: column;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  font-size: 18px;
  text-align: center;
  box-sizing: border-box;
  margin-bottom: -3%;
}

swiper-slide img {
  width: auto;
  max-width: 100%;
  height: auto;
  max-height: 100%;
}

.quickpicklabel{
  margin-left: 3%;
  font-weight: 600;
  color: #6c6c6c;
  font-family: cursive;
  font-size: 28px;
}

.quickpick{
  width: 100%;
  text-align: center;
}

.backgrey{
  background-color: #eaeaea;
}

.backclass{
  --color: black;
}

.md .borderwidth1{
  border-width: 1px;
}

.md .padding0{
  padding: 0px;
}

// .md .ionModal{
//   --height: 76% !important;
// }
